import { writable } from "svelte/store";
const createWritableStore = (key) => {
    const { subscribe, set } = writable();
    return {
        subscribe,
        set,
        useLocalStorage: () => {
            const json = localStorage.getItem(key);
            if (json) {
                set(JSON.parse(json));
            }
            subscribe((current) => {
                if (current) {
                    localStorage.setItem(key, JSON.stringify(current));
                }
            });
        },
        remove: () => {
            localStorage.removeItem(key);
            set(null);
        }
    };
};
const createWritableDefaultStore = (key, defaultKey) => {
    const { subscribe, set } = writable(defaultKey);
    return {
        subscribe,
        set,
        useLocalStorage: () => {
            const json = localStorage.getItem(key);
            if (json) {
                set(JSON.parse(json));
            }
            subscribe((current) => {
                localStorage.setItem(key, JSON.stringify(current));
            });
        },
    };
};
export const apiContext = createWritableStore("apiContext");
export const apiUser = createWritableStore("apiUser");
export const storedEmployees = createWritableDefaultStore("storedEmployees", []);
