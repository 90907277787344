<script>
  export let progress;
</script>

<div class="w-full bg-gray-200 rounded-full dark:bg-gray-700 mt-4">
  <div
    class="transition-width ease-out duration-700 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
    class:bg-blue-600="{progress > 3}"
    style="width: {Math.floor(progress)}%"
  >
    {progress > 2 ? Math.floor(progress) + "%" : "\u2800"}
  </div>
</div>

<style>
    .transition-width {
        transition-property: width;
    }
</style>