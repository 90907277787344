<script>
  import UserInfo from "./Settings/UserInfo.svelte";
  import WeekSelector from "./Settings/WeekSelector.svelte";
  import EmployeeSelector from "./Settings/EmployeeSelector.svelte";
  import SettingsActions from "./Settings/SettingsActions.svelte";

  // Exports from parent
  export let userInfo;
  export let employees;

</script>

<div class="box">
  <img
    src="/images/hoogvliet_logo.png"
    class="w-1/3 lg:w-3/4 mx-auto mb-5"
    alt="hoogvliet logo"
  />

  <UserInfo {userInfo} />

  <hr class="my-3" />

  <p class="font-semibold mb-2">Selecteer week:</p>
  <WeekSelector on:selectWeek />

  <p class="font-semibold mb-2 mt-2">Kies KW medewerkers:</p>
  <EmployeeSelector {employees} />

  <SettingsActions />
</div>

<style lang="scss">
  .button-red {
    @apply focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900;
  }
</style>
