<script lang="ts">
  import { LogOutIcon } from "svelte-feather-icons";
  import * as api from "../../utils/api";

  export let userInfo;
</script>

<div class="flex items-center">
  <div class="flex-1">
    <p class="font-semibold">Ingelogd als:</p>
    {#if userInfo.full_name != null}
      <p>{userInfo.full_name}</p>
    {:else}
      <p>Laden...</p>
    {/if}
  </div>
  <div>
    <button
      on:click={api.logout}
      type="button"
      class="button-red"
      >
      <LogOutIcon size="1.2x" class="icon" />Uitloggen
      </button>
  </div>
</div>

<style lang="scss">
  .button-red {
    @apply focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900
  }
</style>