import { apiContext, apiUser } from "../stores/stores";
const apiUrl = "https://hv0600.personeelstool.nl";
let apiContextValue;
let apiUserValue;
/**
 * Initiate the api (and subcribe to token stores).
 */
export async function init() {
    apiContext.useLocalStorage();
    apiUser.useLocalStorage();
    apiContext.subscribe((apiContext) => (apiContextValue = apiContext));
    apiUser.subscribe((apiUser) => (apiUserValue = apiUser));
    // Check if credentials still work.
    if (isLoggedIn()) {
        const result = await testCredentials();
        if (!result)
            logout();
    }
}
/**
 * Logs out the user.
 */
export function logout() {
    apiContext.remove();
    apiUser.remove();
}
/**
 * Check if the current user is logged in or not.
 * @returns Whether the tokens exist in the store.
 */
export function isLoggedIn() {
    return apiContextValue != undefined && apiUserValue != undefined;
}
/**
 * Check if credentials are still working.
 */
async function testCredentials() {
    const response = await requestApi("api/v2/me", Method.Get);
    if (response.status === 200) {
        return true;
    }
    return false;
}
/**
 * Get current user information.
 *
 */
export async function getUserInfo() {
    const response = await requestApi("api/v2/me", Method.Get);
    const json = await response.json();
    return json["result"];
}
/**
 * Get a list of employees.
 */
export async function getEmployees(active, limit, includeFuture) {
    const response = await requestApi(`api/v2/employees?active=${active}&limit=${limit}&include_future_accounts=${includeFuture}`, Method.Get);
    const json = await response.json();
    return json["result"];
}
/**
 * Get the schedules of employees.
 * @param date The date to get the schedules for.
 * @returns The schedules of the employees.
 */
export async function getSchedules(date) {
    const response = await requestApi("api/v2/employeeSearchSchedules?date=" + date, Method.Get);
    const json = await response.json();
    return json["result"];
}
/**
 * Get the schedule of a certain account ID.
 * @param account_id The account_id to get the schedule for.
 * @param date The date to get the schedule for.
 * @returns The shedule of the account on a certain date.
 */
export async function getOwnSchedule(account_id, date) {
    const response = await requestApi("api/v2/schedules?account_id=" + account_id + "&date=" + date, Method.Get);
    const json = await response.json();
    return json["result"];
}
/**
 * Login to the PMT API.
 * @param username The username to login with.
 * @param password The password to login with.
 * @returns Whether the login was successful (status + message).
 */
export async function login(username, password) {
    const response = await fetch(`${apiUrl}/pmtLoginSso`, {
        method: "POST",
        body: JSON.stringify({
            username: username,
            password: password,
        }),
    });
    const json = await response.json();
    // General error
    if (response.status != 200) {
        return {
            success: false,
            message: "Inloggen mislukt, probeer het opnieuw.",
        };
    }
    // Authentication failed, but request was successful.
    if (!json.result.authenticated) {
        return {
            success: false,
            message: json.result[0].message,
        };
    }
    // Set tokens to store (+ localstorage)
    apiContext.set(json.result.context_token);
    apiUser.set(json.result.user_token);
    return {
        success: true,
        message: "Inloggen gelukt!",
    };
}
/**
 * Make a request with credentials.
 */
async function requestApi(url, method, data) {
    return await fetch(`${apiUrl}/${url}`, {
        body: data,
        method,
        headers: {
            "x-api-context": apiContextValue,
            "x-api-user": apiUserValue,
        },
    });
}
/**
 * Class containing a result message.
 */
class LoginResult {
    constructor() {
        this.success = false;
        this.message = "";
    }
}
/**
 * Enum containing different HTTP methods.
 */
var Method;
(function (Method) {
    Method["Get"] = "GET";
    Method["Post"] = "POST";
    Method["Put"] = "PUT";
    Method["Delete"] = "DELETE";
})(Method || (Method = {}));
