<script>
    import { UnlockIcon } from "svelte-feather-icons";
    import * as pmtApi from "../utils/api";
  
    let username = "";
    let password = "";
    let submitted = false;
    let errorMessage = "";
  
    async function submitLogin(e) {
      e.preventDefault();
      submitted = true;
  
      if (username.length < 1 || password.length < 1) {
        return;
      }
  
      const result = await pmtApi.login(username, password);
  
      if (!result.success) {
        errorMessage = result.message;
      }
      
    }
  </script>
  
  <div class="box md:w-2/3 lg:w-1/2 xl:w-3/12 mx-auto mt-12">
    <div class="p-6">
      <img
        class="w-2/3 lg:w-1/2 mx-auto"
        src="images/hoogvliet_logo.png"
        alt="hoogvliet logo"
      />
      <div class="mt-6">
        <h1 class="text-2xl font-semibold">Inloggen</h1>
        <p>Gebruik je PMT inloggegevens om door te gaan.</p>
  
        {#if errorMessage}
          <div
            class="p-4 mb-4 mt-6 text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
            role="alert"
          >
            <span class="font-medium"
              ><span class="font-semibold">Sorry!</span> {errorMessage}.</span
            >
          </div>
        {/if}
  
        <form class="mt-6">
          <div class="mb-4">
            <label
              for="username"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >Gebruikersnaam</label
            >
  
            <input
              bind:value={username}
              type="text"
              id="username"
              class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
            />
  
            {#if submitted && username.length < 1}
              <p class="mt-2 text-sm text-red-600 dark:text-red-500">
                <span class="font-medium">Oeps!</span> Gebruikersnaam is vereist.
              </p>
            {/if}
          </div>
          <div class="mb-4">
            <label
              for="password"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >Wachtwoord</label
            >
            <input
              bind:value={password}
              type="password"
              id="password"
              class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
            />
  
            {#if submitted && password.length < 1}
              <p class="mt-2 text-sm text-red-600 dark:text-red-500">
                <span class="font-medium">Oeps!</span> Wachtwoord is vereist.
              </p>
            {/if}
          </div>
  
          <button
            on:click={submitLogin}
            type="submit"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg w-full px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            ><UnlockIcon class="inline mr-1" size="1x" /> Inloggen</button
          >
        </form>
      </div>
    </div>
  </div>
  