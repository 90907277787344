export function getWeekNumberFromDate(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
    // Return week number
    return weekNo;
}
export function getFirstDayOfWeekNumber(weekNumber) {
    let currentWeek = getWeekNumberFromDate(new Date());
    let d = new Date();
    var day = d.getDay(), diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    let mondayThisWeek = new Date(d.setDate(diff));
    let weekDiff = weekNumber - currentWeek;
    if (weekDiff < 0) {
        mondayThisWeek.setFullYear(mondayThisWeek.getFullYear() + 1);
        mondayThisWeek.setDate(mondayThisWeek.getDate() - 1);
    }
    mondayThisWeek.setDate(mondayThisWeek.getDate() + 7 * weekDiff);
    return mondayThisWeek;
}
export function numberToDay(number) {
    switch (number) {
        case 0:
            return "Maandag";
        case 1:
            return "Dinsdag";
        case 2:
            return "Woensdag";
        case 3:
            return "Donderdag";
        case 4:
            return "Vrijdag";
        case 5:
            return "Zaterdag";
    }
}
