<script lang="ts">
  import { onMount } from "svelte/internal";
  import Select from "svelte-select";
  import { XIcon } from "svelte-feather-icons";
  import { storedEmployees } from "../../stores/stores";

  export let employees;
  $: items = mapEmployees(employees);

  let currentSelect;
  let selectedEmployees = [];

  onMount(() => {
    storedEmployees.subscribe((value) => (selectedEmployees = value));
  });

  /**
   * Map employees to a readable format (first_name middle_name last_name)
   * @param employees The list of employees to map.
   */

  function mapEmployees(employees) {
    return employees.map(
      (employee) =>
        employee.employee_first_name +
        " " +
        employee.employee_middle_name +
        (employee.employee_middle_name == "" ? "" : " ") +
        employee.employee_last_name
    );
  }

  function addEmployee(event) {
    const fullName = event.detail.value;
    currentSelect = "";
    if (selectedEmployees.includes(fullName)) {
      return;
    }
    selectedEmployees.push(event.detail.value);
    storedEmployees.set(selectedEmployees);
    selectedEmployees = selectedEmployees;
  }

  function removeEmployee(employee) {
    const index = selectedEmployees.indexOf(employee);
    if (index > -1) {
      selectedEmployees.splice(index, 1);
    }
    storedEmployees.set(selectedEmployees);
    selectedEmployees = selectedEmployees;
  }
</script>

<div class="selector">
  <Select
    bind:value={currentSelect}
    {items}
    placeholder="Kies medewerkers..."
    on:select={addEmployee}
  />
</div>
<ul class="my-2">
  {#each selectedEmployees as employee}
    <li class="list-item">
      <div class="flex-1 ml-1">
        {employee}
      </div>
      <div>
        <button
          type="button"
          on:click={() => removeEmployee(employee)}
          class="button-red-outline"><XIcon size="1x" /></button
        >
        <div />
      </div>
    </li>
  {/each}
</ul>

<style lang="scss">
  .list-item {
    @apply flex my-1 shadow-md border dark:border-gray-500 rounded-md p-2 mb-2 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-blue-600 dark:hover:text-blue-300 cursor-default;
  }
  .button-red-outline {
    @apply text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 p-1 font-medium rounded-md text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900;
  }

  .selector {
    --background: transparent;
    --borderRadius: 0.375rem;
    --listBorderRadius: 0.375rem;
  }

  @media (prefers-color-scheme: dark) {
    .selector {
      --itemColor: white;
      --listBackground: #111827;
      --itemHoverBG: #374151;
      --border: 1px solid rgb(107, 114, 128);
    }
  }
</style>
