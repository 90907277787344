<script lang="ts">
  import { onMount } from "svelte/internal";
  import * as api from "./utils/api";
  import Login from "./components/Login.svelte";
  import Reserve from "./components/Reserve.svelte";
  import {apiContext, apiUser} from "./stores/stores";

  let apiContextStore;
  let apiUserStore;

  onMount(async () => {
    apiContext.subscribe((v) => apiContextStore = v);
    apiUser.subscribe((v) => apiUserStore = v);
    await api.init();
  });

  $: loggedIn = apiContextStore && apiUserStore;

</script>

<div>
  {#if loggedIn}
    <Reserve />
  {:else}
    <Login />
  {/if}
</div>

<style global>
  @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  .box {
    @apply bg-white dark:bg-gray-800 dark:text-white p-5 shadow-md rounded-xl;
  }

  body {
    font-family: "Inter", sans-serif;
    @apply bg-gray-100 dark:bg-gray-900;
  }

  .icon {
    @apply inline mr-1;
  }

</style>
