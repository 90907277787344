<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import * as time from "../../utils/time";

  const dispatch = createEventDispatcher();

  let currentDate = new Date();

  let selectedWeek = time.getWeekNumberFromDate(currentDate);

  let thisWeek = time.getWeekNumberFromDate(currentDate);
  let nextWeek = time.getWeekNumberFromDate(
    new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 7
    )
  );
  let nextNextWeek = time.getWeekNumberFromDate(
    new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 14
    )
  );

  function selectWeek(week) {
    selectedWeek = week;
    dispatch("selectWeek", { week });
  }
</script>

<div class="inline-flex rounded-md shadow-sm w-full">
  <button
    on:click={() => selectWeek(thisWeek)}
    class:selected-week={selectedWeek == thisWeek}
    type="button"
    class="button-grouped-gray rounded-l-lg"
  >
    {thisWeek}
  </button>

  <button
    on:click={() => selectWeek(nextWeek)}
    class:selected-week={selectedWeek == nextWeek}
    type="button"
    class="button-grouped-gray"
  >
    {nextWeek}
  </button>

  <button
    on:click={() => selectWeek(nextNextWeek)}
    class:selected-week={selectedWeek == nextNextWeek}
    type="button"
    class="button-grouped-gray rounded-r-lg"
  >
    {nextNextWeek}
  </button>
</div>

<style lang="scss">
  .button-grouped-gray {
    @apply py-2 px-4 w-1/3 text-sm font-medium text-gray-900 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white;
  }
  .selected-week {
    @apply bg-gray-100 dark:bg-gray-700;
  }
</style>
