<script>
  import { onMount } from "svelte";
  import { storedEmployees } from "../stores/stores";
  import ReserveSettings from "./ReserveSettings.svelte";
  import * as api from "../utils/api";
  import * as time from "../utils/time";
  import Schedule from "./Schedule.svelte";

  let selectedWeek = time.getWeekNumberFromDate(new Date());
  let selectedEmployees = [];
  let employees = [];
  let userInfo = {};
  let unfilteredSchedules = [];
  $: schedules = filterSchedules(unfilteredSchedules, selectedEmployees);

  onMount(async () => {
    // Subscribe to employees store
    storedEmployees.useLocalStorage();
    storedEmployees.subscribe((value) => (selectedEmployees = value));
    // Get user data
    userInfo = await api.getUserInfo();
    // Get all available employees
    employees = await api.getEmployees(true, 1000, true);
    // Load schedules for current week
    loadWeekSchedules(time.getWeekNumberFromDate(new Date()));
  });

  async function loadWeekSchedules(weekNumber) {
    let firstDate = time.getFirstDayOfWeekNumber(weekNumber);
    for (let i = 0; i < 6; i++) {
      let date = new Date(firstDate.getTime());
      date.setDate(firstDate.getDate() + i);
      const formattedDate = date.toISOString().split("T")[0];
      let ownSchedule = await api.getOwnSchedule(
        userInfo.account_id,
        formattedDate
      );
      ownSchedule = ownSchedule.map((schedule) => {
        return {
          employee_name: userInfo.full_name,
          schedule_time_from: schedule.schedule_time_from,
          schedule_time_to: schedule.schedule_time_to,
        };
      });
      let loadedSchedules = await api.getSchedules(formattedDate);
      let finalSchedules = loadedSchedules.concat(ownSchedule);
      unfilteredSchedules[i] = finalSchedules;
    }
  }

  function filterSchedules(unfiltered, employees) {
    let filtered = [];
    unfiltered.forEach((daySchedule, i) => {
      let newSchedule = daySchedule.filter((schedule) =>
        employees.includes(schedule.employee_name)
      );
      newSchedule.sort(
        (a, b) =>
          new Date(a.schedule_time_from).getTime() -
          new Date(b.schedule_time_from).getTime()
      );
      filtered[i] = newSchedule;
    });
    return filtered;
  }

  function selectWeek(event) {
    const newWeek = event.detail.week;
    selectedWeek = newWeek;
    unfilteredSchedules = [];
    loadWeekSchedules(newWeek);
  }
</script>

<div class="grid lg:grid-cols-4 gap-6 mt-12 lg:w-2/3 mx-auto">
  <div class="lg:col-span-1">
    <ReserveSettings {userInfo} on:selectWeek={selectWeek} {employees} />
  </div>
  <div class="lg:col-span-3">
    <Schedule {selectedWeek} {schedules} />
  </div>
</div>
