<script lang="ts">
  import * as time from "../utils/time";
  import Loader from "./Loader.svelte";

  export let selectedWeek;
  export let schedules;

  // $: scheduleProgress = (schedules.length / 6 + (schedules.length >= 5 ? 0.17 : (Math.random() * (0.17 - 0) + 0))) * 100;
  $: scheduleProgress = (schedules.length / 5) * 100;

  function formatSchedule(schedule) {
    return (
      schedule.schedule_time_from.split(" ")[1] +
      "-" +
      schedule.schedule_time_to.split(" ")[1]
    );
  }
</script>

<div class="box">
  <div id="printable_schedule">
    <h1 class="text-2xl">
      <span class="font-semibold">Kassa reservelijst</span> week {selectedWeek}
    </h1>
    <hr class="my-2" />

    {#if schedules.length != 6}
      <Loader progress={scheduleProgress} />
    {:else}
      <div class="grid lg:grid-cols-3 gap-6">
        {#each schedules as daySchedule}
          <div>
            <p class="font-semibold text-lg my-2">
              {time.numberToDay(schedules.indexOf(daySchedule))}
            </p>
            <table class="table-auto w-full">
              <tbody>
                {#if daySchedule.length < 1}
                  <p class="text-gray-400 italic">
                    Geen medewerkers beschikbaar
                  </p>
                {/if}
                {#each daySchedule as schedule}
                  <tr>
                    <td class="border p-3">{schedule.employee_name}</td>
                    <td class="border p-3" ><p>{formatSchedule(schedule)}</p></td>
                  </tr>
                {/each}
              </tbody>
            </table>
          </div>
        {/each}
      </div>
    {/if}
  </div>
</div>
