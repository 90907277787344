<script lang="ts">
  import { onMount } from "svelte/internal";
  import { FileTextIcon, SaveIcon, PrinterIcon } from "svelte-feather-icons";
  import { storedEmployees } from "../../stores/stores";

  let fileInputBox;

  let selectedEmployees = [];

  onMount(() => {
    storedEmployees.subscribe((value) => (selectedEmployees = value));
  });

  function onFileSelected(event) {
    let text = event.target.files[0];
    let reader = new FileReader();
    reader.readAsText(text);
    reader.onload = (e) => {
      let loaded = e.target.result;
      selectedEmployees = loaded.toString().split("\r\n");
      storedEmployees.set(selectedEmployees);
    };
  }

  function print() {
    var printwin = window.open("");
    printwin.document.write("\<style\>");
    printwin.document.write(document.getElementById("print_styling").innerHTML);
    printwin.document.write("\</style\>");
    printwin.document.write(
      document.getElementById("printable_schedule").innerHTML
    );
    printwin.stop();
    printwin.print();
    printwin.close();
  }

  function saveTextFile() {
    let text = selectedEmployees.join("\r\n");
    let blob = new Blob([text], { type: "text/plain" });
    let url = URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = "kassa_reservelijst.txt";
    a.click();
  }
</script>

<div class="flex gap-2">
  <button
    on:click={() => {
      fileInputBox.click();
    }}
    type="button"
    class="mt-2 text-white bg-amber-600 hover:bg-amber-700 focus:ring-4 focus:ring-amber-300 font-medium rounded-lg px-5 py-2.5 w-full dark:bg-amber-600 dark:hover:bg-amber-700 focus:outline-none dark:focus:ring-amber-800"
    ><FileTextIcon size="1x" class="inline mr-1" />Lijst inladen</button
  >
  <button
    on:click={saveTextFile}
    type="button"
    class="mt-2 text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:ring-green-300 font-medium rounded-lg px-5 py-2.5 w-full dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800"
    ><SaveIcon size="1x" class="inline mr-1" />Opslaan</button
  >
</div>

<button
  on:click={print}
  type="button"
  class="mt-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg px-5 py-2.5 w-full dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
  ><PrinterIcon size="1x" class="inline mr-1" />Printen</button
>

<input
  style="display:none"
  type="file"
  accept=".txt"
  bind:this={fileInputBox}
  on:change={(e) => onFileSelected(e)}
/>

